import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ActionSheetController, ModalController, NavController} from '@ionic/angular';
import {Storage} from '@ionic/storage';
import {Subscription} from 'rxjs';
import {ClientService} from '../../pages/clients/clients.service';
import {GlobalsService} from '../../globals.service';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {NotificationService} from '../../providers/notification.service';
import {TicketModalComponent} from '../../components/ticket-modal/ticket-modal.component';

@Component({
	selector: "logout-component",
	template: `
		<span class="flex-item right quicksand" style="z-index:10;">
      <ion-button style="background:none;white-space:nowrap;max-width:250px;overflow:hidden;text-overflow:ellipsis;" class="beginLogout" [color]="this.color" (click)="signOut()">
        <ion-icon name="person"></ion-icon>
        <span class="ion-padding-start" style="vertical-align: super;">{{ (auth.current_user$ | async)?.email }}</span>
      </ion-button>
      <ion-badge color="danger" style="vertical-align: super;" *ngIf="( notificationsService.unread$ | async ).length > 0">
        {{ ( notificationsService.unread$ | async ).length }}
      </ion-badge>
    </span>
  `
})

export class LogoutComponent {
	@Input() color: string;
  @Output() showHelp: EventEmitter<any> = new EventEmitter();
  @Output() hideHelp: EventEmitter<any> = new EventEmitter();

  currentUserSubscription: Subscription;
  notificationsSubscription: Subscription;

	constructor(private storage: Storage,
              private actionSheetCtrl: ActionSheetController,
              private clientService: ClientService,
              private globalService: GlobalsService,
              private navCtrl: NavController,
              private modalCtrl: ModalController,
              public notificationsService: NotificationService,
              public auth: AuthService,
              private router: Router) {
    this.notificationsSubscription = this.notificationsService.notifications$.subscribe( data => {
      if ( data && data.length == 0 ) {
        this.storage.ready().then(() => {
          this.storage.get("notifications").then( val => {
            if ( val && val.length != data.length ) this.notificationsService.setNotifications(val)
          })
        })
      }
    })
	}

  signOut() {
	  let buttons = [
      {
        text: 'Report Bug',
        handler: () => {
          this.modalCtrl.create({
            component: TicketModalComponent,
            cssClass: 'modal-fullscreen'
          }).then(modal => modal.present())
        }
      },
      {
        text: 'View Notifications',
        handler: () => {
          this.router.navigate(['notifications'])
        }
      },
      {
        text: 'Sign Out',
        cssClass: "logout-button",
        role: 'destructive',
        handler: async () => {
          let unreadNotifications = this.notificationsService.getUnreadNotifications()
          if (unreadNotifications.length) {
            let shouldNavigateToNotifications = await this.notificationsService.launchUnreadNotificationModal(unreadNotifications.length)
            if (shouldNavigateToNotifications) {
              await this.router.navigate(['/notifications'])
              return
            }
          }
          this.notificationsSubscription.unsubscribe();
          this.auth.logout().then(() => {
            this.navCtrl.navigateRoot("/login")
          })
        }
      }
    ]

    this.actionSheetCtrl.create({
      header: 'Logged in as: ' + this.auth.getUser().email,
      buttons: buttons
    }).then( actionSheet => actionSheet.present())
  }
}
