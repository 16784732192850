import {IMealPlan} from './meal-plan';
import {ITag} from './tag';

export var bools = {
    "cravings": "Cravings",
    "dinner_protein": "Too much Protein",
    "oil": "Too little fat",
    "late_dinner": "Late Dinner",
    "alcohol": "Alcohol",
    "meal_skipping": "Meal Skipping",
    "no_bfast": "Bad Timing",
    "eating_out": "Accidentally Off Program",
    "veggies": "Too Many Veggies",
    "too_few_veggies": "Too Few Veggies",
    "constipated": "Constipated",
    "exercise_isses": "Too Much Exercise",
    "stress": "Stress",
    "vibration": "Vibration",
    "limited_veggies": "Limited Veggies",
    "hungry": 'Hungry',
    "too_little_salt": 'Too Little Salt',
    "late_bfast": 'Late Breakfast',
    "intentional_off_prog": 'Intentionally Off Program',
    "not_measuring_protein": "Not Measuring Protein",
    "timing": "Meal Timing Issues"
}
export var remedies = {
  'ld_swap': 'Swap Lunch and Dinner',
  'increase_salt': 'Increase Salt',
  'increase_exer': 'Increase Exercise',
  'decrease_exer': 'Decrease Exercise',
  'increase_water': 'Increase Water',
  'decrease_bars': 'Decrease Bars',
  'decrease_products': 'Decrease Products',
  'decrease_protein':'Decrease Protein',
  'increase_fats': 'Increase Fats',
  'increase_veggies': 'Increase Veggies',
  'planned_for_events': 'Planned for Events',
  'elim_late_snack': 'Eliminate After Dinner Snack'
}

export var issues = [
  { name: "cravings", display: "Cravings" },
  { name: "dinner_protein", display: "Too much Protein" },
  { name: "oil", display: "Not Enough Fat" },
  { name: "late_dinner", display: "Late Dinner" },
  { name: "alcohol", display: "Alcohol" },
  { name: "meal_skipping", display: "Skipping Meals" },
  { name: "eating_out", display: "Accidentally Off Program" },
  { name: "veggies", display: "Too Many Veggies" },
  { name: "too_few_veggies", display: "Too Few Veggies" },
  { name: "constipated", display: "Constipated" },
  { name: "exercise_isses", display: "Too Much Exercise" },
  { name: "stress", display: "Stress" },
  { name: "limited_veggies", display: "Too Many Limited" },
  { name: "too_little_salt", display: 'Too Little Salt' },
  { name: "sick", display: 'Sick' },
  { name: "late_bfast", display: 'Late Breakfast' },
  { name: "intentional_off_prog", display: 'Intentionally Off Program' },
  { name: "not_measuring_protein", display: "Not Measuring Protein" },
  { name: "drinks", display: "4+ Drinks / Wk" },
  { name: "sleep", display: "Poor Sleep" },
  { name: "low_electro", display: "Low Electrolytes" },
  { name: "travel", display: "Travel"},
  { name: "not_listen", display: "Not Following Coaching" },
  { name: "timing", display: "Meal Timing Issues"},
  { name: 'high_caffeine', display: 'High Caffeine' },
]

export var remedyBools = [
  { name: 'ld_swap', display: 'Swap Lunch and Dinner' },
  { name: 'increase_salt', display: 'Increase Salt' },
  { name: 'increase_exer', display: 'Increase Exercise' },
  { name: 'decrease_exer', display: 'Decrease Exercise' },
  { name: 'increase_water', display: 'Increase Water' },
  { name: 'decrease_bars', display: 'Decrease Bars' },
  { name: 'decrease_products', display: 'Decrease Products' },
  { name: 'decrease_protein', display: 'Decrease Protein' },
  { name: 'increase_fats', display: 'Increase Fats' },
  { name: 'increase_veggies', display: 'Increase Veggies' },
  { name: 'veggie_solution', display: 'Veggie Solution' },
  { name: 'planned_for_events', display: 'Planned for Events' },
  { name: 'elim_late_snack', display: 'Eliminate After Dinner Snack' },
  { name: 'measure_protein', display: 'Measure Protein' },
  { name: 'smt_suggest', display: 'SMT Suggested' },
  { name: 'cardio', display: '30 min Cardio' },
  { name: 'inc_electro', display: 'Increase Electrolytes' },
  { name: 'reduced_meds', display: 'Reduced Meds' },
  { name: 'referral_card', display: 'Referral Card' },
]

export var times = [
  { icon: 'wake', name: "wake_on_time", display: "Wake", desc: null},
  { icon: 'breakfast-egg', name: "bfast_on_time", display: "Breakfast?", desc: "bfast_d"},
  { icon: 'apple', name: "snack1_on_time", display: "Snack 1?", desc: "snack1_d"},
  { icon: 'lunch', name: "lunch_on_time", display: "Lunch?", desc: "lunch_d"},
  { icon: 'apple', name: "snack2_on_time", display: "Snack 2?", desc: "snack2_d"},
  { icon: 'dinner', name: "dinner_on_time", display: "Dinner?", desc: "dinner_d"},
  { icon: 'apple', name: "snack3_on_time", display: "Snack 3?", desc: "snack3_d"},
  { icon: 'sleep', name: "bed_on_time", display: "Bed", desc: null}
]

export var explanation = [
  { name: "exp_fat", display: "Fat"},
  { name: "exp_carbs", display: "Carbs"},
  { name: "exp_protein", display: "Protein"},
  { name: "hbc_goals", display: "HBC & Goals"},
]

export var hunger_cravings = {
  issues: [
    { name: "intentional_off_prog", display: 'Intentionally Off Program' },
    { name: "eating_out", display: "Accidentally Off Program" },
    { name: "late_dinner", display: "Late Dinner" },
    { name: "meal_skipping", display: "Skipping Meals" },
    { name: "oil", display: "Not Enough Fat" },
  ],
  remedies: [
    { name: 'increase_fats', display: 'Increase Fats' },
    { name: 'planned_for_events', display: 'Planned for Events' },
  ]
}

export var veggies = {
  issues: [
    { name: "too_few_veggies", display: "Too Few Veggies" },
    { name: "limited_veggies", display: "Too Many Limited" },
  ],
  remedies: [
    { name: 'veggie_solution', display: "Veggie Solution"}
  ]
}

export var protein = {
  issues: [
    { name: "dinner_protein", display: "Too much Protein" },
  ],
  remedies: [
    { name: 'decrease_products', display: 'Decrease Products' },
    { name: 'decrease_bars', display: 'Decrease Bars' },
    { name: 'decrease_protein', display: 'Decrease Protein' },
    // { name: 'increase_fats', display: 'Increase Fats' },
    { name: 'measure_protein', display: 'Measure Protein' }
  ]
}

export var additional = {
  issues: [
    { name: "drinks", display: "4+ Drinks / Wk" },
    { name: "stress", display: "Stress" },
    { name: "sleep", display: "Poor Sleep" },
    { name: "exercise_isses", display: "Too Much Exercise" },
    { name: "low_electro", display: "Low Electrolytes" },
    { name: "constipated", display: "Constipated" },
    { name: "sick", display: "Sick"},
    { name: "not_listen", display: "Not Following Coaching" },
    { name: "travel", display: "Travel"},
    { name: "timing", display: "Meal Timing Issues"},
    { name: 'high_caffeine', display: 'High Caffeine' },
  ],
  remedies: [
    { name: 'smt_suggest', display: 'SMT Suggested' },
    { name: 'cardio', display: '30 min Cardio' },
    { name: 'inc_electro', display: 'Increase Electrolytes' },
    { name: 'ld_swap', display: 'Swap Lunch and Dinner' },
    { name: 'increase_water', display: 'Increase Water' },
    { name: 'reduced_meds', display: 'Reduced Meds' },
    { name: 'referral_card', display: 'Referral Card' },
  ]
}

export var emojis = {
  'awesome': "🤩",
  'happy': "🙂",
  'confused': "🤔",
  'hungry': "🤤",
  'sad': "😞",
  'disappointed': "😞",
  'lying': "🤥",
  'angry': "😤",
  'tired': "😴"
}

export var moods = [
  // { name: 'awesome', display: "🤩"},
  // { name: 'happy', display: "🙂"},
  { name: 'confused', display: "🤔"},
  { name: 'hungry', display: "🤤" },
  // { name: 'sad', display: "😭"},
  { name: 'disappointed', display: "😞", help_text: "disappointed" },
  { name: "lying", display: "🤥" },
  { name: 'angry', display: "😤"},
  { name: 'tired', display: "😴"},
]

export var csat = {
  '5': "🤩",
  '4': "🙂",
  '3': "😐",
  '2': "🙁",
  '1': "🤬",
}

export interface IBasicRepositioning {
  id?: number;
  num_products?: number;
  general_products?: number;
  number_of_bars?: number;
  oz_protein?: number;
  has_meal_plan?: boolean;
  intentional_off_prog?: boolean;
  intentional?: string;
  accidental?: string;
  eating_out?: boolean;
  description?: string;
  csat?: number;
  check_in_id?: number;
  public_comment?: string;
  tags?: ITag[];
  created_at?: string;
}

export class BasicRepositioning {
  public id?: number;
  public num_products?: number;
  public number_of_bars?: number;
  public general_products?: number;
  public oz_protein?: number;
  public has_meal_plan?: boolean;
  public intentional_off_prog?: boolean;
  public intentional?: string;
  public accidental?: string;
  public eating_out?: boolean;
  public description?: string;
  public check_in_id?: number;
  public csat?: number;
  public public_comment?: string;
  public created_at?: string;

  constructor( data: Partial<IBasicRepositioning> ) {
    Object.assign(this, data);
  }
}

export interface IRepositioning extends IBasicRepositioning {
  healthy_fats?: number;
  client_id?: number;

  // Meal Plan
  wake_time?: string;
  wake_d?: string;
  bfast_on_time?: string;
  bfast_d?: string;
  snack1_on_time?: string;
  snack1_d?: string;
  lunch_on_time?: string;
  lunch_d?: string;
  snack2_on_time?: string;
  snack2_d?: string;
  dinner_on_time?: string;
  dinner_d?: string;
  snack3_on_time?: string;
  snack3_d?: string;
  bed_on_time?: string;
  bed_d?: string;
  meal_plan?: IMealPlan;
  meal_plan_attributes?: IMealPlan;

  // Issues
  cravings?: boolean;
  alcohol?: boolean;
  constipated?: boolean;
  veggies?: boolean;
  dinner_protein?: boolean;
  late_dinner?: boolean;
  stress?: boolean;
  oil?: boolean;
  water?: boolean;
  travel?: boolean;
  meal_skipping?: boolean;
  product_doubling?: boolean;
  exercise_isses?: boolean;
  too_few_veggies?: boolean;
  too_little_salt?: boolean;
  sick?: boolean;
  late_bfast?: boolean;
  drinks?: boolean;
  low_electro?: boolean;
  not_listen?: boolean;
  sleep?: boolean;
  timing?: boolean;

  // Remedies
  ld_swap?: boolean;
  increase_salt?: boolean;
  increase_exer?: boolean;
  decrease_exer?: boolean;
  increase_water?: boolean;
  decrease_bars?: boolean;
  decrease_products?: boolean;
  decrease_protein?: boolean;
  increase_fats?: boolean;
  increase_veggies?: boolean;
  measure_protein?: boolean;
  planned_for_events?: boolean;
  veggie_solution?: boolean;
  smt_suggest?: boolean;
  reduced_meds?: boolean;
  referral_card?: boolean;
  cardio?: boolean;
  inc_electro?: boolean;

  // Moods
  awesome?: boolean;
  happy?: boolean;
  confused?: boolean;
  sad?: boolean;
  angry?: boolean;
  tired?: boolean;
  lying?: boolean;
  hungry?: boolean;
  csat?: number;

  // Notes
  smt?: string;

  // Explain
  exp_carbs?: boolean;
  exp_fat?: boolean;
  exp_protein?: boolean;
  hbc_goals?: boolean;

  // Other
  created_at?: string;
  updated_at?: string;

  taggings_attributes?: any
  taggings?: any
}

export interface IDraftRepositioningEntry {
  data: IDraftRepositioning;
  key: string;
  checkInTime: string;
}

export interface IDraftRepositioning extends IRepositioning {
  form_id?: string;
  name?: string;
  check_in_date?: string;
}

export class DraftRepositioning implements IDraftRepositioning {
  public form_id?: string;
  public name?: string;
  public check_in_date?: string;

  constructor(data: Partial<IDraftRepositioning>) {
    Object.assign(this, data);
  }

  setupEmojis() {
    let overview = {
      bools: [],
      remedy_bools: [],
      emojis: [],
      explanations: []
    }

    for ( let issue of issues ) {
      if ( this[issue.name] ) {
        overview.bools.push(issue.display)
      }
    }

    for ( let remedy of remedyBools ) {
      if ( this[remedy.name] ) {
        overview.remedy_bools.push(remedy.display)
      }
    }

    for (let key in emojis) {
      if ( this[key] ) {
        overview.emojis.push(emojis[key])
      }
    }

    for ( let exp of explanation ) {
      if ( this[exp['name']] ) {
        overview.explanations.push(exp['display'])
      }
    }

    return overview
  }
}
